import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { Component, EventEmitter, OnInit, Output } from "@angular/core";

import { AuthService } from "@fiba/data-services";
import { MapPolicy } from "../../../@fiba/models";

interface NavMenuItem {
  name: string;
  icon: string[] | string;
  route: string;
  order: number;
  policy: number;
  children: NavMenuItem[];
  isExpanded: boolean;
}

@Component({
  selector: "nav-menu",
  templateUrl: "./navmenu.component.html",
  styleUrls: ["./navmenu.component.scss"],
  animations: [
    trigger("enterAnimation", [
      state(
        "*",
        style({
          "overflow-y": "hidden",
          height: "*",
        })
      ),
      state(
        "void",
        style({
          height: "0",
          "overflow-y": "hidden",
        })
      ),
      transition("* => void", animate("250ms ease-out")),
      transition("void => *", animate("250ms ease-in")),
    ]),
  ],
})
export class NavMenuComponent implements OnInit {
  public readonly currentYear: number = new Date().getUTCFullYear();

  public menus: NavMenuItem[];
  @Output() public menuToggled = new EventEmitter<boolean>();

  constructor(protected authService: AuthService) {
    this.isFolded = false;
    this.menus = [];
  }

  public _isFolded: boolean;

  public set isFolded(val: boolean) {
    this._isFolded = val;
    this.menuToggled.emit(this._isFolded);
  }

  public ngOnInit() {
    this.authService.getIsFrontOfficeUserPromise().then((isFrontOffice) => {
      const menus: NavMenuItem[] = [
        {
          name: "Home",
          icon: "fa-home",
          route: "/home",
          order: 10,
          policy: null,
        },
        {
          name: "Dashboard",
          icon: "fa-list-alt",
          route: "/td-portal-dashboard",
          order: 11,
          policy: MapPolicy.TechnicalMeetingOperatorPortal,
        },
        //{
        //    name: 'My Contracts',
        //    icon: 'fa-user',
        //    route: '/agent-user-contracts',
        //    order: 11,
        //    policy: MapPolicy.AgentRelationsContractsBrowse,
        //},
        {
          name: "Personal Details",
          icon: "fa-street-view",
          route: "/agent-user-informations",
          order: 12,
          policy: MapPolicy.AgentRelationsContractsBrowse,
        },
        {
          name: "Personal Details",
          icon: "fa-street-view",
          route: "/game-official-user-informations",
          order: 13,
          policy: MapPolicy.GameOfficialsElicensePortalBrowse,
        },
        {
          name: "My e-License",
          icon: "fa-fw fa-id-card-o",
          route: "/game-official-my-e-license",
          order: 14,
          policy: MapPolicy.GameOfficialsElicensePortalMyELicenseBrowse,
        },
        {
          name: "Persons",
          icon: "fa-street-view",
          route: "/persons",
          order: 20,
          policy: MapPolicy.PersonsRead,
        },
        {
          name: isFrontOffice ? "Players in my NF" : "Players",
          icon: ["fa-street-view", "small-fa fa-basketball"],
          route: "/players",
          order: 30,
          policy: MapPolicy.PlayersBrowse,
        },
        {
          name: "WABC Members",
          icon: "fa-person-chalkboard",
          route: "/wabc-members",
          order: 33,
          policy: MapPolicy.WabcBrowse,
        },
        {
          name: "WABC Courses",
          icon: "fa-calendar",
          route: "/wabc-courses",
          order: 34,
          policy: MapPolicy.WabcBrowse,
        },
        {
          name: "Coaches",
          icon: "fa-person-chalkboard",
          route: "/coaches",
          order: 35,
          policy: MapPolicy.CoachesBrowse,
        },
        {
          name: "Agents",
          icon: "fa-user-secret",
          order: 37,
          policy: MapPolicy.AgentsBrowse,
          route: "/agents",
          isExpanded: true,
          children: [
            {
              name: "Agents list",
              icon: ["fa-user-secret"],
              route: "/agents/search",
              policy: MapPolicy.AgentsBrowse,
              order: 370,
            },
            {
              name: "Dashboard",
              icon: ["fa-dashboard", "small-fa fa-user-secret"],
              route: "/agents/dashboard",
              order: 372,
              policy: MapPolicy.AgentsBrowse,
            },
            {
              name: "Pre-registration",
              icon: ["fa-user-secret", "small-fa fa-calendar"],
              route: "/agents/pre-registered",
              order: 378,
              policy: MapPolicy.AgentPreRegistrationsBrowse,
            },
            {
              name: "Registration",
              icon: ["fa-user-secret", "small-fa fa-registered"],
              route: "/agents/registrations",
              order: 379,
              policy: MapPolicy.AgentRegistrationsBrowse,
            },
          ],
        },
        {
          name: "Technical delegates",
          icon: "fa-user-tie",
          route: "/technical-delegates",
          order: 40,
          policy: MapPolicy.OfficialRegistrationsBrowse,
        },
        {
          name: "Organisations",
          icon: "fa-university",
          route: "/organisations",
          order: 50,
          policy: MapPolicy.OrganisationsBrowse,
        },
        {
          name: "NF",
          icon: "fa-flag-o",
          route: "/nationalfederations",
          order: 60,
          policy: MapPolicy.NationalFederationsBrowse,
        },
        {
          name: "Clubs",
          icon: "fa-people-group",
          route: "/clubs",
          order: 70,
          policy: MapPolicy.ClubsBrowse,
        },
        {
          name: "Sanctions",
          icon: "fa-ban",
          route: "/sanction-applications",
          order: 71,
          policy: MapPolicy.SanctionsBrowse,
        },
        {
          name: isFrontOffice ? "Licenses in my NF" : "Player licenses",
          icon: "fa-id-card-o",
          route: "/player-licenses",
          order: 80,
          policy: MapPolicy.PlayerLicensesBrowse,
        },
        {
          name: "Coach licenses",
          icon: "fa-id-card-o",
          route: "/coach-license-requests",
          order: 90,
          policy: MapPolicy.CoachLicensesBrowse,
        },
        {
          name: "Game officials",
          icon: "fa-clipboard-user",
          order: 91,
          policy: MapPolicy.OfficialLicensesBrowse,
          route: "/official-licenses",
          isExpanded: true,
          children: [
            {
              name: "Licensing",
              icon: ["fa-calendar", "small-fa fa-id-card"],
              route: "/official-licenses/periods",
              order: 910,
              policy: MapPolicy.OfficialLicensesBrowse,
            },
          ],
        },
        {
          name: "Transfer requests",
          icon: "fa-handshake-o",
          route: "/transfer-requests",
          order: 95,
          policy: MapPolicy.TransferRequestsBrowse,
        },
        {
          name: "Transfer dashboard",
          icon: "fa-tachometer",
          route: "/transfers-dashboard",
          order: 96,
          policy: MapPolicy.TransferRequestsDashboardBrowse,
        },
        {
          name: "Insurance policy",
          icon: "fa-medkit",
          route: "/insurance-policies",
          order: 99,
          policy: MapPolicy.InsurancePoliciesBrowse,
        },
        {
          name: "Competitions",
          icon: ["fa-sitemap", "small-fa fa-trophy"],
          route: "/competitions",
          order: 100,
          policy: MapPolicy.CompetitionsBrowse,
        },
        {
          name: "Games",
          icon: ["fa-sitemap", "small-fa fa-basketball"],
          route: "/games",
          order: 101,
          policy: MapPolicy.CompetitionsOperationRead,
        },
        {
          name: "Event",
          icon: "fa-calendar",
          route: "/events",
          order: 110,
          policy: MapPolicy.EventsBrowse,
        },
        {
          name: "Venues",
          icon: "fa-building-o",
          route: "/venues",
          order: 120,
          policy: MapPolicy.VenuesBrowse,
        },
        {
          name: "Venue Registrations",
          icon: "fa-building-circle-check",
          route: "/venue-form-registrations",
          order: 121,
          policy: MapPolicy.FormRegistrationVenueBrowse,
        },
        {
          name: "Site Visit",
          icon: "fa-check-square-o",
          route: "/site-visit",
          order: 122,
          policy: MapPolicy.FormRegistrationSiteVisitBrowse,
        },
        {
          name: "Financial packages",
          icon: "fa-money",
          route: "/financial-packages",
          order: 130,
          policy: MapPolicy.FinancialReportingBrowse,
        },
        {
          name: "Payments",
          icon: "fa-credit-card",
          route: "/payments-admin",
          order: 140,
          policy: MapPolicy.PaymentsBrowse,
        },
        {
          name: "Player release",
          icon: ["fa-user", "small-fa fa-unlock-alt"],
          route: "/player-release",
          order: 145,
          policy: MapPolicy.PlayerReleaseRead,
        },
        {
          name: "Form Registration",
          icon: "fa-file-text-o",
          route: "/form-registration-templates",
          order: 500,
          policy: MapPolicy.FormRegistrationTemplateBrowse,
        },
        {
          name: "E&VC Configuration",
          icon: ["fa-building-shield", "small-fa fa-cog"],
          order: 600,
          policy: MapPolicy.EVCRead,
          route: "/equipment-configuration",
          children: [
            {
              name: "Approval Categories",
              icon: ["fa-clipboard-check"],
              route: "/equipment-configuration/approval-category",
              order: 1,
              policy: MapPolicy.EVCRead,
            },
            {
              name: "Fees",
              icon: ["fa-hand-holding-dollar"],
              route: "/equipment-configuration/fees",
              order: 2,
              policy: MapPolicy.EVCRead,
            },
            {
              name: "Questionnaires",
              icon: ["fa-file-text-o", "small-fa fa-cog"],
              route: "/equipment-configuration/questionnaires",
              order: 3,
              policy: MapPolicy.FormRegistrationTemplateBrowse,
            },
            {
              name: "Test reports",
              icon: ["fa-file-waveform", "small-fa fa-cog"],
              route: "/equipment-configuration/test-reports",
              order: 4,
              policy: MapPolicy.FormRegistrationTemplateBrowse,
            },
          ],
        },
        {
          name: "E&VC",
          icon: "fa-building-shield",
          order: 700,
          policy: MapPolicy.EVCRead,
          route: "/equipment-configuration",
          isExpanded: true,
          children: [
            {
              name: "Dashboard",
              icon: "fa-gauge",
              route: "/equipment-dashboard",
              order: 1,
              policy: MapPolicy.EVCRead,
            },
            {
              name: "Applicants",
              icon: ["fa-brands fa-wpforms", "small-fa fa-handshake"],
              route: "/equipment-partner-applications",
              order: 2,
              policy: MapPolicy.EVCRead,
            },
            {
              name: "E&VC Partners",
              icon: ["fa-handshake", "small-fa fa-basketball"],
              route: "/equipment-fiba-partners",
              order: 3,
              policy: MapPolicy.EVCRead,
            },
            {
              name: "Product applications",
              icon: ["fa-brands fa-wpforms", "small-fa fa-box"],
              route: "/equipment-product-applications",
              order: 4,
              policy: MapPolicy.EVCRead,
            },
            {
              name: "Products",
              icon: "fa-boxes-stacked",
              route: "/equipment-products",
              order: 5,
              policy: MapPolicy.EVCRead,
            },
            {
              name: "Test institutes",
              icon: ["fa-building", "small-fa fa-list-check"],
              route: "/equipment-test-institutes",
              order: 6,
              policy: MapPolicy.EVCRead,
            },
            {
              name: "Agreements",
              icon: "fa-file-signature",
              route: "/equipment-agreements",
              order: 7,
              policy: MapPolicy.EVCRead,
            },
            {
              name: "Payments",
              icon: "fa-file-invoice",
              route: "/equipment-payments",
              order: 8,
              policy: MapPolicy.EVCRead,
            },
          ],
        },
        {
          name: "Web CIS",
          icon: ["fa-desktop", "small-fa fa-basketball"],
          route: "/web-cis",
          order: 798,
          policy: MapPolicy.WebCIS,
        },
        //{
        //    name: 'LSI',
        //    icon: ['fa-desktop', 'small-fa fa-basketball'],
        //    route: '/lsi',
        //    order: 799,
        //    policy: MapPolicy.Lsi,
        //},
        {
          name: "Ranking",
          icon: "fa-ranking-star",
          route: "/ranking",
          order: 800,
          policy: MapPolicy.RankingBrowse,
        },
        {
          name: "My Applications",
          icon: ["fa-user", "small-fa fa-brands fa-wpforms"],
          route: "/partner-equipment-product-applications",
          order: 801,
          policy: MapPolicy.EquipmentPartnerBrowse,
        },
        {
          name: "My Products",
          icon: "fa-user-shield",
          route: "/partner-equipment-products",
          order: 802,
          policy: MapPolicy.EquipmentPartnerBrowse,
        },
        {
          name: "My Agreements & Certificates",
          icon: ["fa-user", "small-fa fa-file-signature"],
          route: "/partner-equipment-agreements",
          order: 803,
          policy: MapPolicy.EquipmentPartnerBrowse,
        },
        {
          name: "My Payments",
          icon: ["fa-building", "money-check-dollar"],
          route: "/partner-equipment-payments",
          order: 804,
          policy: MapPolicy.EquipmentPartnerBrowse,
        },
        {
          name: "My Test Reports",
          icon: "fa-list",
          route: "/test-report",
          order: 810,
          policy: MapPolicy.TestReportBrowse,
        },
        {
          name: "My Company Profile",
          icon: ["fa-building", "small-fa  fa-user"],
          route: "/partner-equipment-profile",
          order: 820,
          policy: MapPolicy.EquipmentPartnerBrowse,
        },
        {
          name: "Reports",
          icon: "fa-file-pdf-o",
          route: "/reports",
          order: 830,
          policy: MapPolicy.ReportsBrowse,
        },
        {
          name: "User Profile",
          icon: "fa-user",
          route: "/user-profile",
          order: 840,
          policy: null,
        },
        {
          name: "Administration",
          icon: "fa-cog",
          order: 850,
          policy: MapPolicy.AdministrationBrowse,
          route: "/administration",
          isExpanded: true,
          children: [
            {
              name: "Person roles",
              icon: ["fa-street-view", "small-fa fa-cog"],
              route: "/administration/role-configuration",
              order: 1,
              policy: MapPolicy.AdministrationBrowse,
            },
            {
              name: "Org. categories",
              icon: ["fa-university", "small-fa fa-cog"],
              route: "/administration/organisation-categories",
              order: 2,
              policy: MapPolicy.AdministrationBrowse,
            },
            {
              name: "Conf. items",
              icon: ["fa-bars", "small-fa fa-cog"],
              route: "/administration/configuration-items",
              order: 3,
              policy: MapPolicy.AdministrationBrowse,
            },
            {
              name: "Item categories",
              icon: ["fa-square fa-bars", "small-fa fa-cog"],
              route: "/administration/item-categories",
              order: 4,
              policy: MapPolicy.AdministrationBrowse,
            },
            {
              name: "Conf. templates",
              icon: ["fa-envelope", "small-fa fa-cog"],
              route: "/administration/configuration-templates",
              order: 5,
              policy: MapPolicy.AdministrationBrowse,
            },
            {
              name: "Notifications",
              icon: ["fa-envelope", "small-fa fa-paper-plane-o"],
              route: "/administration/notifications",
              order: 6,
              policy: MapPolicy.AdministrationBrowse,
            },
          ],
        },
        {
          name: "Users",
          icon: "fa-users",
          route: "/users",
          order: 900,
          policy: MapPolicy.UsersBrowse,
        },
        {
          name: "Merge",
          icon: "fa-compress",
          order: 950,
          policy: MapPolicy.MergeBrowse,
          route: "/merge",
          isExpanded: true,
          children: [
            {
              name: "Merge venues",
              icon: ["fa-building-o", "small-fa fa-compress"],
              route: "/merge/merge-venues",
              order: 951,
              policy: MapPolicy.VenuesMergeBrowse,
            },
            {
              name: "Merge persons",
              icon: ["fa-street-view", "small-fa fa-compress"],
              route: "/merge/merge-persons",
              order: 952,
              policy: MapPolicy.PersonsMergeBrowse,
            },
            {
              name: "Merge clubs",
              icon: ["fa-users", "small-fa fa-compress"],
              route: "/merge/merge-clubs",
              order: 955,
              policy: MapPolicy.ClubsMergeBrowse,
            },
          ],
        },
        {
          name: "Standing headers",
          icon: ["fa-object-group", "small-fa fa-cog"],
          route: "/standing-headers",
          order: 10011,
          policy: MapPolicy.StandingHeadersBrowse,
        },
      ] as NavMenuItem[];

      this.processMenus(menus, this.menus);
    });
  }

  public toggleMenu() {
    this.isFolded = !this._isFolded;
  }

  public isStackIcon(menuItem: NavMenuItem): boolean {
    return (
      menuItem.icon &&
      menuItem.icon instanceof Array &&
      menuItem.icon.length > 0
    );
  }

  protected insertMenuItem(menuItem: NavMenuItem, destMenus: NavMenuItem[]) {
    let i;
    destMenus = destMenus || [];
    for (i = 0; i < destMenus.length; i++) {
      if (destMenus[i].order > menuItem.order) {
        break;
      }
    }
    destMenus.splice(i, 0, menuItem);
  }

  protected processMenus(
    menus: NavMenuItem[],
    destMenus: NavMenuItem[]
  ): Promise<boolean> {
    const promises: Array<Promise<boolean>> = [];
    if (menus && menus.length) {
      for (const menuItem of menus) {
        const promise = this.processMenu(menuItem, destMenus).then<boolean>(
          (x) => Promise.resolve<boolean>(x)
        );
        promises.push(promise);
      }
      return Promise.all(promises).then<boolean>(
        (isChildrenAuthorised) => {
          const oneChildTrue =
            isChildrenAuthorised.find((x) => x === true) || false;
          return Promise.resolve<boolean>(oneChildTrue);
        },
        (error) => Promise.reject(error)
      );
    } else {
      return Promise.resolve<boolean>(true);
    }
  }

  protected processMenu(
    menuItem: NavMenuItem,
    destMenus: NavMenuItem[]
  ): Promise<boolean> {
    const newItem = this.copyItem(menuItem);
    return this.processMenus(menuItem.children, newItem.children).then<boolean>(
      (isChildrenAuthorised) =>
        this.authService.isNavigationAuthorized(newItem.route).then<boolean>(
          (authorized) => {
            if (authorized && isChildrenAuthorised) {
              this.insertMenuItem(newItem, destMenus);
              return Promise.resolve<boolean>(true);
            }
            return Promise.resolve<boolean>(false);
          },
          (error) => Promise.reject(error)
        ),
      (error) => Promise.reject(error)
    );
  }

  protected copyItem(menuItem: NavMenuItem): NavMenuItem {
    return {
      icon: menuItem.icon,
      name: menuItem.name,
      order: menuItem.order,
      route: menuItem.route,
      policy: menuItem.policy,
      children: [],
    } as NavMenuItem;
  }
}
