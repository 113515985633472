<div class="navmenu">
  <nav class="main-navigation">
    <ul>
      <li *ngFor="let item of menus">
        <a
          *ngIf="!item.children?.length"
          [ngClass]="{
            'child-active': item.children?.length,
            'k-d-inline-flex': !_isFolded,
            'k-d-block': _isFolded
          }"
          [routerLinkActive]="['active']"
          [routerLink]="item.route"
        >
          <i *ngIf="!isStackIcon(item)" class="fa fa-xl {{ item.icon }}"></i>
          <div *ngIf="isStackIcon(item)">
            <i
              *ngFor="let icon of item.icon; let first = first"
              [ngClass]="{ 'secondary fa-xs': !first, 'fa-xl': first }"
              class="fa {{ icon }}"
            ></i>
          </div>
          <span [hidden]="_isFolded">{{ item.name }}</span>
        </a>
        <div *ngIf="item.children?.length">
          <div
            [ngClass]="{
              'k-d-inline-flex': !_isFolded,
              'k-d-block': _isFolded
            }"
            (click)="item.isExpanded = !item.isExpanded"
          >
            <i *ngIf="!isStackIcon(item)" class="fa fa-xl {{ item.icon }}"></i>
            <div *ngIf="isStackIcon(item)">
              <i
                *ngFor="let icon of item.icon; let first = first"
                [ngClass]="{ 'secondary fa-xs': !first, 'fa-xl': first }"
                class="fa {{ icon }}"
              ></i>
            </div>
            <span [hidden]="_isFolded">{{ item.name }}</span>
            <i
              [hidden]="_isFolded"
              [ngClass]="{
                'fa-plus': !item.isExpanded,
                'fa-minus': item.isExpanded
              }"
              class="fa fa-sm expand"
            ></i>
          </div>
          <ul *ngIf="item.isExpanded" [@enterAnimation]>
            <li *ngFor="let childItem of item.children">
              <a
                [routerLinkActive]="['active']"
                [routerLink]="childItem.route"
                [ngClass]="{
                  'k-d-inline-flex': !_isFolded,
                  'k-d-block': _isFolded
                }"
              >
                <i
                  *ngIf="!isStackIcon(childItem)"
                  class="fa fa-xl {{ childItem.icon }}"
                ></i>
                <ng-container *ngIf="isStackIcon(childItem)">
                  <i
                    *ngFor="let icon of childItem.icon; let first = first"
                    [ngClass]="{ 'secondary fa-xs': !first, 'fa-xl': first }"
                    class="fa {{ icon }}"
                  ></i>
                </ng-container>
                <span [hidden]="_isFolded">{{ childItem.name }}</span>
              </a>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </nav>
  <div (click)="toggleMenu()" class="fold-menu">
    <i [hidden]="_isFolded" class="fa fa-arrow-circle-left"></i>
    <span [hidden]="_isFolded">Hide menu</span>
    <i [hidden]="!_isFolded" class="fa fa-arrow-circle-right"></i>
  </div>
  <div class="copyright">
    &copy;&nbsp;FIBA&nbsp;
    <span [hidden]="_isFolded">2016-{{ currentYear }}</span>
  </div>
</div>
