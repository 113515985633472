<div
  [ngClass]="{
    'site-banner-border-title-red': optionStyles?.style.color == 'red',
    'site-banner-border-title-blue': optionStyles?.style.color == 'blue',
    'site-banner-border-title-green': optionStyles?.style.color == 'green',
    'site-banner-border-title-yellow': optionStyles?.style.color == 'yellow',
  }"
  class="top-menu"
>
  <a
    routerLink="/"
    class="site-title"
    title="FIBA Management and Administration Platform"
  >
    <img src="assets/logo_fiba.png" alt="logo FIBA" />
    FIBA Management and Administration Platform
  </a>

  <div *ngIf="optionStyles?.name" class="site-banner-title">
    <span>{{ optionStyles.caption }}</span>
  </div>

  <div *ngIf="user && userInfo" class="log-user">
    <div
      *ngIf="userInfo.shouldShowOrganisationInfo"
      class="organisation-header"
    >
      <img
        *ngIf="userInfo.organisationCountryLogoUrl"
        [src]="userInfo.organisationCountryLogoUrl"
        class="flag-custom"
        alt="organisation country logo"
      />
      <div>
        <span class="k-text-uppercase">{{
          userInfo.organisationCountryName
        }}</span
        >, {{ userInfo.organisationName }}
      </div>
    </div>
    <kendo-avatar
      routerLink="user-profile"
      [themeColor]="'light'"
      [border]="true"
      [initials]="user.profile.given_name[0] + user.profile.family_name[0]"
    ></kendo-avatar>
    <span (click)="logout()" class="logout"
      ><i class="fa fa-lg fa-sign-out"></i
    ></span>
  </div>
</div>
