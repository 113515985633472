import {Component} from '@angular/core';
import { ItemDataService, UserDataService } from '../../@fiba/data-services';
import { CoreDisplayTexts, ItemCategoryCodes } from '../../@fiba/models';
import { NotificationService, NotificationType } from '../../@fiba/utils/notification.service';

@Component({
    selector: 'root',
    templateUrl: './root.component.html',
})
export class RootComponent {
    constructor( public userDataService: UserDataService,
        public itemDataService: ItemDataService,
        public notificationService: NotificationService) {
    }

    ngAfterViewInit(): void {
        this.userDataService.isCorrectMfaAccount().subscribe(
            (success) => {
                if (success) {
                    this.itemDataService.fetchItemByCategoryAndCode(ItemCategoryCodes.CoreDisplayText, CoreDisplayTexts.ErrormessageMFA).subscribe(
                        (item) => {
                            this.notificationService.emitNotification(NotificationType.Error, item.description);
                        },
                        (error) => {
                            this.notificationService.emitNotification(NotificationType.Error, error);

                        },
                    );
                }
            },
            (error) => {
                this.notificationService.emitNotification(NotificationType.Error, error);

            },
        );
        this.userDataService.isGeneralMessage().subscribe(
            (success) => {
                if (success) {
                    this.itemDataService.fetchItemByCategoryAndCode(ItemCategoryCodes.CoreDisplayText, CoreDisplayTexts.Adminmessage).subscribe(
                        (item) => {
                            this.notificationService.emitNotification(NotificationType.Warning, item.description);
                        },
                        (error) => {
                            this.notificationService.emitNotification(NotificationType.Error, error);

                        },
                    );
                }
            },
            (error) => {
                this.notificationService.emitNotification(NotificationType.Error, error);

            },
        );
    }
}
