import { NgModule } from "@angular/core";

import { SharedModule } from "@app/shared.module";
import { TopMenuComponent } from "./topmenu.component";

const EXTERNAL_COMPONENTS: any[] = [TopMenuComponent];

@NgModule({
  imports: [SharedModule.forRoot()],
  declarations: [EXTERNAL_COMPONENTS],
  exports: [EXTERNAL_COMPONENTS],
  providers: [],
})
export class TopMenuModule {}
