import { Component } from "@angular/core";

@Component({
  selector: "fibaNotificationDisplayWrapper",
  templateUrl: "./notification-display-wrapper.component.html",
  styles: [
    `
      .notif-actions a:not(:last-child):after {
        display: inline-block;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        content: "/";
      }
    `,
  ],
})
export class NotificationDisplayWrapperComponent {}
